export default {
  methods: {
    getImageUrl: function (serviceName) {
      try {
        return require('../assets/img/serviceIcon/' + serviceName + '.png')
      } catch (err) {
        return require('../assets/img/serviceIcon/default.png')
      }
    }
  }
}
